import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Button } from '@/components/atoms/button'
import { TitleWithButton } from '@/components/atoms/title-with-button'
import { FormLayout } from '@/components/template/form-layout'
import { Layout } from '@/components/template/layout'
import { LanguageType } from '@/i18n'
import { getValueByLanguage } from '@/libs/get-value-by-language'
import { spacerTopStyle } from '@/styles/common'
import { formButtonStyle } from '@/styles/form'
import { useLocation, useNavigate } from 'react-router-dom'
import { AccountInfo, checkUserDataValid } from '@/features/user/account-slice'

import moment from 'moment'
import { smartCheckIn } from '@/features/checkIn/smart-check-in-slice'
import { ItemLabel } from '@/components/molecules/item-label'
import dayjs from 'dayjs'
import { convertedOccupationValueToLabel } from '@/utils/form/occupation'
import { Loading } from '@/components/organisms/loading'
import { errorHandler } from '@/libs/errors'
import { css } from '@emotion/react'
import { displayYen } from '@/libs/text'
import { isEmpty } from 'lodash'
import { getCreditCard } from '@/features/user/user-card-slice'
import { useDeleteCheckIn } from '@/hooks/use-delete-check-in'
import { restoreCustomCheckInData } from '@/features/checkIn/custom-check-in-field-slice'
import { ContentWrapper } from '@/components/template/content-wrapper'
import { CheckBox } from '@/components/atoms/check-box'
import { CheckInStep } from '@/components/organisms/checkIn/check-in-step'
import { AssignSpaceInput } from '@/features/space/space-reservation-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { SelectableTimeSelection } from '@/features/checkIn/time-selection-slice'
import { timeToMinutes } from '@/features/space/spaces-slice'
import { createDateString } from '@/utils/date'

export const CheckInConfirm: React.FC = () => {
  const [isChecked, setIsChecked] = useState(true)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const pickByLanguage = usePickByLanguage()
  const { deleteCheckIn } = useDeleteCheckIn()

  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const { facilityBasicInfo } = useAppSelector(state => state.hotelGuide)
  const checkInData = useAppSelector(state => state.checkIn.checkInData.fields)
  const customFields = useAppSelector(state => state.customFields.customCheckin)
  const customFieldHasInputField = customFields.filter(({ inputType }) => inputType !== 'none')
  const checkInPaymentData = useAppSelector(state => state.checkIn.checkInPayments.checkInPaymentData)
  const userCreditCardData = useAppSelector(state => state.creditCard.userCreditCardData)

  const cardPayLoading = useAppSelector(state => state.checkout.creditCardPayment.loading)
  const smartCheckInLoading = useAppSelector(state => state.smartCheckIn.loading)
  const backupReceiptPDFLoading = useAppSelector(state => state.reservation.backupReceiptPDF.loading)
  const { selectedTimes, timeSelections } = useAppSelector(state => state.timeSelection)

  const onSubmit = async () => {
    try {
      const assignSpaces: (AssignSpaceInput & { reservationId: string })[] = []
      Object.keys(selectedTimes).forEach(selectionId => {
        const { selection, reservationsByTime } = getTimeSelection(selectionId)
        Object.keys(selectedTimes[selectionId]).forEach(date => {
          if (!selectedTimes[selectionId][date]) {
            return
          }
          reservationsByTime[date].forEach(reserveId => {
            const reserve = checkInData.selectedReservations?.find(r => r.reservationId === reserveId)
            if (!reserve) {
              return
            }
            const usageTime = selectedTimes[selectionId][date]
            const usageFrom = dayjs(usageTime)
            const usageTo = usageFrom.add(timeToMinutes(selection.minUsageTime), 'minute')
            const spaceAssign = {
              pax: reserve.paxTotal,
              reservationUserName: reserve.guestName || '',
              timeSelectionId: selectionId,
              reservationId: reserveId,
              spaceId: selection.spaceId,
              usageFrom: usageFrom.format('YYYY-MM-DD HH:mm:ss'),
              usageTo: usageTo.format('YYYY-MM-DD HH:mm:ss'),
              answer: '',
            }
            if (selection.stockPerReservation === 'single') {
              // single の場合は1つだけ追加
              assignSpaces.push(spaceAssign)
            } else {
              // single でない場合は人数分追加
              for (let i = 0; i < reserve.paxTotal; i++) {
                assignSpaces.push(spaceAssign)
              }
            }
          })
        })
      })

      const smartCheckInData = {
        checkinId: checkInData.id,
        userId: accountInfo.id,
        checkin: {
          hotelId: facilityBasicInfo.hotelId,
          jpnGtdStatus: 0,
          approvedStatus: 0,
          nextPlaceToStay:
            checkInData.nextAccommodation === 'Other' && checkInData.nextPlace !== 'Home' ? checkInData.nextPlace || 'その他' : '自宅',
          previousPlaceToStay:
            checkInData.previousAccommodation === 'Other' && checkInData.previousPlace !== 'Home'
              ? checkInData.previousPlace || 'その他'
              : '自宅',
          checkinDateTime: dayjs(`${checkInData.checkInDate} ${checkInData.checkInTime}`),
          accompany: checkInData.companions?.map(companion => {
            return language === 'ja'
              ? { name: `${companion.lastName} ${companion.firstName}` }
              : { name: `${companion.firstName} ${companion.lastName}` }
          }),
          guest: {
            ...accountInfo,
            prefecture: '',
            telephone: checkInData.reservationTel,
            totalMember: checkInData.guests,
          },
          user: accountInfo,
        },
        hotelId: facilityBasicInfo.hotelId,
        reservationIds: checkInData.reservationIds,
        checkinNote: {
          memo: customFieldHasInputField.length
            ? customFieldHasInputField?.reduce((memo, i) => memo + `\n${i.jpTitle}:${i.value || '-'}`, '施設からのご案内')
            : '',
        },
        deliveryAgreementStatus: isChecked ? 'deliverable' : 'nonDeliverable',
        assignSpaces,
        paymentType: checkInPaymentData.paymentType || '',
        receiptName: checkInPaymentData.receiptName || accountInfo.name,
        lang: language,
        _checkInData: checkInData,
      }
      await dispatch(smartCheckIn(smartCheckInData)).unwrap()
      deleteCheckIn()
      navigate('/checkin-accept')
    } catch (error) {
      errorHandler({ error })
    }
  }

  const loadCreditCardData = async () => {
    try {
      await dispatch(getCreditCard(accountInfo.id)).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
  }

  const getTimeSelection = (timeSelectionId: string): SelectableTimeSelection => {
    return timeSelections.find(({ selection }) => selection.id === timeSelectionId) as SelectableTimeSelection
  }

  const getTargetGuestName = (timeSelectionId: string, date: string): string => {
    if ((checkInData?.selectedReservations?.length || 0) < 2) {
      return ''
    }
    return getTimeSelection(timeSelectionId)
      .reservationsByTime[date].map(reserveId => {
        const reserve = checkInData.selectedReservations?.find(r => r.reservationId === reserveId)
        return t('honorific', { name: reserve?.guestName || reserve?.userName || '-' })
      })
      .join(', ')
  }

  useEffect(() => {
    if (isEmpty(checkInData)) {
      return navigate('/checkin')
    }

    if (!isEmpty(accountInfo) && !checkUserDataValid(accountInfo)) {
      return navigate('/checkin-edit')
    }

    dispatch(restoreCustomCheckInData())
  }, [])

  useEffect(() => {
    if (isEmpty(userCreditCardData) && checkInPaymentData.paymentType === 'creditCard' && accountInfo.id) {
      loadCreditCardData()
    }
  }, [accountInfo])

  return (
    <Layout>
      <CheckInStep current={3} />
      <ContentWrapper style={{ paddingTop: '1rem' }}>
        <FormLayout>
          <TitleWithButton
            title={t('Accommodation information')}
            onClick={() => navigate({ pathname: '/checkin-edit', search: 'hash=accommodation' })}
          />

          <div css={[spacerTopStyle, itemLabelBlockStyle]}>
            <ItemLabel
              label={t('Check-in Date and Time')}
              value={`${dayjs(checkInData.checkInDate).format('YYYY/MM/DD')} ${checkInData.checkInTime}`}
            />

            <ItemLabel label={t('Number of guests')} value={`${checkInData.guests}人`} />

            <ItemLabel
              label={t('Companions')}
              value={checkInData.companions?.map(companion => `${companion.lastName} ${companion.firstName}`) || '-'}
            />

            <ItemLabel
              label={t('Previous place of stay')}
              value={t(
                checkInData.previousAccommodation === 'Home' ? checkInData.previousAccommodation : checkInData.previousPlace || 'Other',
              )}
            />

            <ItemLabel
              label={t('Next destination')}
              value={t(checkInData.nextAccommodation === 'Home' ? checkInData.nextAccommodation : checkInData.nextPlace || 'Other')}
            />
          </div>
        </FormLayout>

        <FormLayout layoutCss={spacerTopStyle}>
          <TitleWithButton
            title={t('Representative information')}
            onClick={() => navigate({ pathname: '/checkin-edit', search: 'hash=representative' })}
          />

          <div css={[spacerTopStyle, itemLabelBlockStyle]}>
            <ItemLabel label={t('Nationality')} value={t(accountInfo.nationality === 'JPN' ? 'Japanese' : 'non-Japanese')} />

            <ItemLabel label={t('Full name')} value={t(accountInfo.name || '')} />

            <ItemLabel label={t('Date of birth')} value={moment(accountInfo.birthDate).format('YYYY/MM/DD')} />

            <ItemLabel label={t('Gender')} value={accountInfo?.gender === 'M' ? t('Male') : t('Female')} />

            <ItemLabel label={t('Phone number')} value={accountInfo.telephone} />

            <ItemLabel label={t('Address')} value={accountInfo.address} />

            <ItemLabel
              label={t('Occupation')}
              value={convertedOccupationValueToLabel({
                occupation: accountInfo.occupation,
                otherOccupation: accountInfo.otherOccupation,
                company: accountInfo.company,
              })}
            />
          </div>
        </FormLayout>

        {(!isEmpty(customFieldHasInputField) || !isEmpty(selectedTimes)) && (
          <FormLayout layoutCss={spacerTopStyle}>
            <TitleWithButton title={t('Guidance')} onClick={() => navigate({ pathname: '/checkin-edit', search: 'hash=guidance' })} />

            <div css={[spacerTopStyle, itemLabelBlockStyle]}>
              {customFieldHasInputField.map(customField => (
                <ItemLabel
                  key={customField.value}
                  label={getValueByLanguage({ object: customField, key: 'title', lang: language as LanguageType })}
                  value={customField.value}
                />
              ))}

              {Object.keys(selectedTimes).map((selectionId, i) => {
                const enabledSelectedTimes = Object.keys(selectedTimes[selectionId]).filter(date => selectedTimes[selectionId][date])
                if (enabledSelectedTimes.length === 0) {
                  return <></>
                }
                return (
                  <React.Fragment key={selectionId}>
                    <ItemLabel
                      label={pickByLanguage(getTimeSelection(selectionId).selection.title)}
                      value={enabledSelectedTimes.sort().map(date => {
                        const day = createDateString({ date: selectedTimes[selectionId][date], language })
                        const time = dayjs(selectedTimes[selectionId][date]).format('H:mm')
                        const guestName = getTargetGuestName(selectionId, date)
                        return `${day} ${time} ${guestName}`
                      })}
                    />
                  </React.Fragment>
                )
              })}
            </div>
          </FormLayout>
        )}

        {checkInPaymentData?.paymentAmount && checkInPaymentData.paymentAmount > 0 && (
          <FormLayout layoutCss={spacerTopStyle}>
            <TitleWithButton
              title={t('Payment details')}
              onClick={() =>
                navigate('/checkin-payment', { state: { isEdit: true, receiptName: location.state.receiptName ?? accountInfo.name } })
              }
            />

            <div css={[spacerTopStyle, itemLabelBlockStyle]}>
              <ItemLabel label={t('Amount billed')} value={displayYen(checkInPaymentData.paymentAmount.toString())} />
              {checkInPaymentData.paymentType === 'creditCard' ? (
                <ItemLabel label={t('Payment options')} value={userCreditCardData.cardNumber} />
              ) : (
                <ItemLabel label={t('Payment options')} value={t('Pay at the front desk')} />
              )}
              {checkInPaymentData.receiptName && <ItemLabel label={t('Receipt Name')} value={checkInPaymentData.receiptName} />}
            </div>
          </FormLayout>
        )}

        <div style={{ marginTop: 24, marginBottom: 32, paddingLeft: '2rem', paddingRight: '1.5rem', lineHeight: 1.5 }}>
          <CheckBox
            customLabelStyle={css({ fontSize: '12px', fontWeight: 'normal' })}
            name="agree"
            checked={isChecked}
            label={t('Receive campaign information and special offers by e-mail')}
            onChange={() => setIsChecked(!isChecked)}
          />
        </div>

        <Loading loading={cardPayLoading || smartCheckInLoading || backupReceiptPDFLoading}>
          <Button buttonCss={[spacerTopStyle, formButtonStyle]} text={t('Check-in')} onClick={async () => await onSubmit()} />
        </Loading>
      </ContentWrapper>
    </Layout>
  )
}

const itemLabelBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
})
